.pageHeader {
  width: 100%;
  /* text-align: center;
  font-size: 32px; */
  padding: 40px 20px 40px 20px;
  color: #33332d;
  background-size: cover;
  background-position: bottom;
}

.pageHeader span{
  background: #fff;
  width: 100%;
  display: block;
  padding: 10px;
  border-radius: 5px;
}

.pageContent {
  position: relative;
  /* top: -36px; */
  /* margin: 0 30px; */
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin: 0 20px;
}

.card{
  -webkit-box-shadow: 0 .46875rem 2.1875rem rgba(0,0,0,.03),0 .9375rem 1.40625rem rgba(0,0,0,.03),0 .25rem .53125rem rgba(0,0,0,.05),0 .125rem .1875rem rgba(0,0,0,.03);
  box-shadow: 0 .46875rem 2.1875rem rgba(0,0,0,.03),0 .9375rem 1.40625rem rgba(0,0,0,.03),0 .25rem .53125rem rgba(0,0,0,.05),0 .125rem .1875rem rgba(0,0,0,.03);
  border-width: 0;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-radius: .25rem;
  /* margin: 20px; */
}
.card-header{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-width: 1px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: .625rem;
  height: 3.5rem;
}
.card-header, .card-title {
  text-transform: uppercase;
  color: rgba(0,0,0,.7);
  font-weight: 700;
  font-size: .88rem;
}
.card-header {
  padding: .75rem 1.25rem;
  padding-top: 0.75rem;
  padding-right: 1.25rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0;
  color: inherit;
  background-color: #fff;
  border-bottom: 1px solid rgba(0,0,0,.125);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.table-responsive{
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.tableContainer {
  padding: 20px;
  box-shadow: 0px 0px 10px #ccc;
}


.tableContainer table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid #e0e0e0;
}

thead th {
  padding: 15px 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
}

.tableContainer table tbody td {
  padding: 15px;
  color: #303248;
  font-size: 14px;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
}

.tableContainer table tbody tr:nth-child(odd) {
  background-color: rgba(0,0,0,.04);
}
.tableContainer table tbody tr:nth-child(even) {
  background-color: #fff;
}
.tableWrapper {
  overflow-x: auto;
}

.card table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid rgb(224, 224, 224);
}

.card table thead th {
  padding: 15px 10px;
  /* background-color: #33332d; */
  text-align: center;
  /* color: #ffffff; */
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.card table.subTable thead th {
  padding: 5px 10px;
  background-color: #e4e5e8;
  text-align: center;
  color: #b5b4b4;
  text-transform: uppercase;
  font-size: 12px;
}
.card table tbody td {
  padding: 15px;
  color: #303248;
  font-size: 14px;
  border-bottom: 1px solid rgb(224, 224, 224);
  text-align: center;
}
.card table.leftTable tbody td {
  padding: 0px;
  color: #303248;
  font-size: 14px;
  border-bottom: 1px solid rgb(224, 224, 224);
  text-align: left;
}

.card table tbody tr:nth-child(odd) {
  background-color: rgb(249, 249, 249);
}

.card table tbody tr:nth-child(even) {
  background-color: #ffffff;
}
.card table.subTable tbody tr:nth-child(odd), .card table tbody tr.subtable {
  background-color: #fff9ea;
}

.card table.subTable tbody tr:nth-child(even), .card table tbody tr.subtable {
  background-color: #fffbf4;
}
.pageContainer{
  display: flex;
  flex-direction: row;
}
.pageCard{
  position: relative;
  margin: 0 30px;
  display: block;
  width: 50%;
}

.pageGrid {
  position: relative;
  /* top: -40px; */
  margin: 0 30px;
  display: grid;
  grid-template-columns: 5fr 3fr;
  /* grid-template-columns: 1fr; */
  grid-gap: 20px;
}

.gridCard {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #ccc;
  margin-bottom: 30px;
}

.gridHead {
  border-radius: 10px 10px 0 0;
  background-color: #848474;
  color: #ffffff;
  padding: 12px 15px;
  font-weight: bold;
  font-size: 14px;
  height: 40px;
  display: flex;
  justify-content: space-between;
}

.gridHeadRight {
  color: #ffffff;
  text-align: right;
  font-weight: normal;
}

.gridHeadRight:hover {
  text-decoration: underline;
}

.gridSubhead {
  background-color: #fafafa;
  color: #303248;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: bold;
  border-left: #303248 4px solid;
}

.editarSubhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editarSubhead .editarBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-color: #33332d;
  background-color: #33332d;
  color: #fff;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.editarSubhead .editarBtn:hover {
  border-color: #535353;
  background-color: #535353;
}

.editarSubhead img {
  width: 15px;
}

.listPage {
  position: relative;
  /* top: -40px; */
  margin: 0 30px;
}

.datosGrid {
  position: relative;
  margin: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  font-size: 12px;
}
.infoContent {
  position: relative;
  margin: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  font-size: 12px;
}
.datosGridComentarios {
  position: relative;
  margin: 20px;
  font-size: 12px;
}

.tableIcon {
  width: 20px;
  margin: 0 7px;
  cursor: pointer;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.contentButton {
  background-color: #34710d;
  color: #ffffff;
  padding: 8px 15px;
  width: fit-content;
  border-radius: 5px;
}

.contentButton img {
  height: 10px;
  margin-right: 10px;
}

.asignarButton {
  background-color: #848474;
  color: #ffffff;
  padding: 8px 15px;
  width: fit-content;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.asignarButton img {
  height: 10px;
  margin-right: 10px;
}

.titleContent {
  color: #303248;
  font-size: 24px;
  font-weight: bold;
  border-left: #848474 5px solid;
  padding-left: 10px;
  margin: 10px 0;
}

.nuevoAAFFForm {
  display: flex;
  flex-direction: column;
}
.nuevoAAFFForm select {
  width: 100%;
  padding: 8px 15px;
  border-bottom: #303248 1px solid;
}
.nuevoAAFFForm input{
  width: 100%;
  padding: 10px 15px;
  border-bottom: #303248 1px solid;
}

.nuevoAAFFForm input[type="submit"] {
  padding: 10px 15px;
  color: #ffffff;
  background-color: #848474;
  border-radius: 5px;
  font-weight: bold;
  width: fit-content;
  align-self: flex-end;
  border: none;
}

.listadoBtn {
  padding: 8px 15px;
  color: #ffffff;
  background-color: #848474;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;
  display: inline-block;
}

.listadoBtn img {
  height: 15px;
  margin-right: 10px;
}

.descargarBtn {
  display: flex;
  align-content: center;
  color: #848474;
  margin-top: 15px;
  width: fit-content;
  cursor: pointer;
}

.descargarBtn img {
  height: 15px;
  margin-right: 10px;
}

.bigBtn {
  padding: 10px 15px;
  display: flex;
  align-content: center;
  background-color: #848474;
  width: fit-content;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  color: #ffffff !important;
  margin: 0 10px
}

.bigBtn img {
  height: 20px;
  margin-right: 15px;
  filter: brightness(0) invert(1);
}

.buscador {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.buscador button {
  padding: 10px 15px;
  color: #ffffff;
  background-color: #848474;
  border-radius: 5px;
  font-weight: bold;
  width: fit-content;
  align-self: flex-end;
  border: none;
}
.buscador button:hover{
  background-color: #6c6c65;
}
.buscador input {
  padding: 10px 15px;
  border-radius: 5px;
  border: #7e86a3 1px solid;
  margin-right: 10px;
}
.buscador p {
  margin-left: 20px;
  padding: 10px 15px;
}
.buscador select {
  padding: 10px 15px;
  border-radius: 5px;
  border: #7e86a3 1px solid;
  margin-right: 10px;
}
.exportar{
  display: flex;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  flex-direction: row-reverse;
}
.hojaVisitaContainer {
  padding: 30px;
}

.hojaVisitaContainer label {
  display: block;
  padding-bottom: 10px;
}

.hojaVisitaContainer input[type="text"],
.hojaVisitaContainer input[type="number"],
.hojaVisitaContainer input[type="date"], 
.hojaVisitaContainer select {
  background-color: #ffffff;
  width: 100%;
  padding: 10px 15px;
}

.hojaVisitaContainer input:disabled {
  background-color: #ffffff;
  color: #303248;
}

.hojaVisitaContainer img {
  width: 100%;
}

.hojaVisitaContainer h2 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.hojaVisitaContainer input[type="radio"] {
  margin-right: 10px;
}

.hojaVisitaContainer input[type="submit"] {
  padding: 10px 15px;
  color: #ffffff;
  background-color: #848474;
  border-radius: 5px;
  font-weight: bold;
  width: fit-content;
  align-self: flex-end;
  border: none;
  margin: 20px 0 20px auto;
}

.hojaVisitaContainer .bordeForm{
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.hojaVisitaContainer .pregunta-principal{
  display: flex;
  align-items: baseline;
  margin-bottom: 30px;
  font-weight: bold;
  position: relative;
}

.hojaVisitaContainer .pregunta-principal label:first-child {
  flex-basis: 50%; 
  flex-shrink: 0; 
  padding-right: 10px;
  padding-left: 0;
}

.hojaVisitaContainer .pregunta-principal label {
  display: flex;
  align-items: center;
  /* flex-basis: 5%;
  flex-shrink: 0;  */
  flex-grow: 0;
  font-size: 12px;
  padding: 0 5px;
}

.hojaVisitaContainer .subpreguntas {
  background: #f9f9f9;
  padding: 15px;
  margin-left: 25px;
  border-radius: 5px;max-height: 0; /* Oculto por defecto */
  overflow: hidden; /* Oculta el contenido desbordado */
  opacity: 0; /* Totalmente transparente cuando está oculto */
  transition: max-height 0.5s ease, opacity 0.5s ease; /* Transición suave de altura y opacidad */
}

.hojaVisitaContainer .subpreguntas.show {
  max-height: 720px; /* Altura máxima visible (ajustable según contenido) */
  opacity: 1; /* Totalmente visible */
}
.hojaVisitaContainer .observacion {
  overflow: hidden; /* Oculta el contenido desbordado */
  opacity: 0; /* Totalmente transparente cuando está oculto */
  transition: max-height 0.5s ease, opacity 0.5s ease; /* Transición suave de altura y opacidad */
}

.hojaVisitaContainer .observacion.show {
  max-height: 500px; /* Altura máxima visible (ajustable según contenido) */
  opacity: 1; /* Totalmente visible */
}

.hojaVisitaContainer .subpreguntas .pregunta {
  display: flex; /* Usamos Flexbox para alinear los items */
  font-size: 12px;
  justify-content: flex-start;
  align-items: center; /* Alineamos los elementos verticalmente */
  margin-bottom: 10px; /* Espacio entre las preguntas */
}

.hojaVisitaContainer .subpreguntas .pregunta label:first-child {
  flex-basis: 65%; /* Establecemos un ancho fijo o puedes usar % */
  flex-shrink: 0; /* Evitamos que se reduzca este ancho */
  padding-right: 15px; /* Un poco de espacio entre la pregunta y los radios */
}

.hojaVisitaContainer .subpreguntas .pregunta label {
  display: flex;
  align-items: center;
  flex-basis: 10%; /* Ancho fijo para cada opción de radio */
  flex-shrink: 0; /* Evitamos que se reduzca */
}

.hojaVisitaContainer .subpreguntas .pregunta input[type="radio"] {
  margin-right: 5px; /* Espacio entre el radio button y el texto */
}

.radioGroup {
  margin-bottom: 30px;
}

.contentBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media print {
  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}
@media (max-width: 768px) {
  .buscador{
    flex-direction: column;
  }
  .buscador input, .buscador select{
    margin-bottom: 10px;
  }
  .buscador button{
    margin-right: 10px;
  }
}

@media (max-width: 900px) {
  .pageContainer{
    flex-direction: column;
  }
  .pageCard{
    width: auto;
  }
}

.makeStyles-paper-2 {
  border: 2px solid transparent !important;
  padding: 30px 32px 30px !important;
  border-radius: 10px;
}
.makeStyles-paper-2:focus-visible {
  outline: none;
}
.PrivateTabIndicator-colorPrimary-2{
  background-color: #6c6c65;  
}
.PrivateTabIndicator-colorPrimary-16 {
  background-color: #6c6c65;
}
.MuiTab-textColorPrimary.Mui-selected{
  color: #6c6c65; 
}